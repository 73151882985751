import { toHead } from 'vue-datocms'

import type { Tag } from '~/graphql/generated'
import type { ToMetaTagsType } from 'vue-datocms'

type SeoMetaTags = {
  meta: ({ name?: string; content?: string } & ReturnType<
    typeof toHead
  >['meta'][number])[]
} & Omit<ReturnType<typeof toHead>, 'meta'>

/**
 * Creates a head object for the page with the given SEO data from DatoCMS which can be passed to Nuxt's `useHead`.
 *
 * @param seoData - The SEO data from DatoCMS. (e.g. landingPage.seo)
 * @param isMainLandingPage - Whether the page is the main landing page or not. If so, overrides the robots meta tag by making it page "index, follow".
 * @returns The head object for the page.
 */
export function getDatoPageHeadData(
  seoData: Tag[],
  isMainLandingPage: boolean = false,
) {
  if (!seoData || !seoData.length) return {}

  const seoMetaTags = toHead(seoData as ToMetaTagsType) as SeoMetaTags
  const noIndexMetaTag = seoMetaTags.meta?.find(
    (meta) => meta.name === 'robots' && meta.content?.includes('noindex'),
  )
  // if present, add nofollow to the noIndexMetaTag
  if (noIndexMetaTag)
    noIndexMetaTag.content = `${noIndexMetaTag.content}, nofollow`

  // Remove noIndexMetaTag when on the main landing page
  if (isMainLandingPage && noIndexMetaTag) {
    seoMetaTags.meta = seoMetaTags.meta?.filter(
      (meta) => meta !== noIndexMetaTag,
    )
  }

  return {
    ...seoMetaTags,
    meta: [
      // Add "index, follow" robots meta tag when on main landing page or `noIndexMetaTag` isn't present
      // if it is present, the "noindex, nofollow" meta data gets added by `seoMetaTags.meta`
      isMainLandingPage || !noIndexMetaTag
        ? {
            key: 'robots',
            name: 'robots',
            content: 'index, follow',
          }
        : undefined,
      ...(seoMetaTags.meta || []),
    ],
    htmlAttrs: {
      class: 'tw-scroll-smooth',
    },
    // add microsoft clarity script
    script: [
      {
        innerHTML: `(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "opnh3zmqm2");`,
      },
    ],
  }
}
